import Facebook from '../resources/facebook.svg';
import Instagram from '../resources/instagram.svg';
import React from 'react';
import Twitter from '../resources/twitter.svg';
import { authors } from '../data/authors';
import styled from '@emotion/styled';

const StyledShare = styled.div`
  margin-top: var(--gutter-medium);

  .links {
    display: flex;
  }

  a {
    &:not(:last-child) {
      margin-right: var(--gutter-small);
    }
  }

  img {
    height: 40px;
  }
`;

const Share = ({ title, url, author }) => (
  <StyledShare>
    <h3>Share Blog Post</h3>
    <div className="links">
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          `${title} by ${author.name} ${url}`
        )}`}
        target="_blank"
      >
        <img src={Twitter} alt="Twitter" />
      </a>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank">
        <img src={Facebook} alt="Twitter" />
      </a>
    </div>
  </StyledShare>
);

export default Share;
