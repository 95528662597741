export const authors = {
    gabor: {
        name: 'Gabor Erdosi',
        image: 'gabor.jpg'
    },
    donal: {
        name: 'Donal Collins',
        image: 'donal.jpg'
    },
    jonfurniss: {
        name: 'Jon Furniss',
        image: 'jonfurniss.jpg'
    }
}
