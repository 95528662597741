import { BLOG_URL, DESCRIPTION } from "../utils/constants";
import { CommentCount, Disqus } from "gatsby-plugin-disqus";
import { Link, graphql } from "gatsby";

import Author from "../components/author";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";
import React from "react";
import Share from "../components/share";
import { authors } from "../data/authors";
import { breakpoints } from "../styles/variables";
import styled from "@emotion/styled";

const StyledPostTemplate = styled.div`
  max-width: var(--textContainerNormal);
  padding: var(--gutter-normal) 0;
  margin: var(--gutter-medium) auto;
  background: var(--white);
  border: var(--border-light);

  .container {
    padding-left: var(--gutter-normal);
    padding-right: var(--gutter-normal);
  }

  .date {
    margin-bottom: var(--gutter-small);
  }

  .teaser {
    height: 40rem;
    background-size: cover;
    background-position: center;
    border-top: var(--border-light);
    border-bottom: var(--border-light);
    margin-bottom: var(--gutter-small);

    @media (max-width: ${breakpoints.medium}) {
      height: 35rem;
    }
  }

  h2 {
    margin: var(--gutter-medium) 0 var(--gutter-small);
  }

  ul,
  ol {
    margin: var(--gutter-normal) 0;
  }

  li + li {
    margin-top: var(--gutter-small);
  }

  img {
    max-width: 100%;
    margin-bottom: var(--gutter-small);
  }

  pre {
    overflow-x: scroll;
  }
`;

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        name
        date
        teaser
        author
      }
      fields {
        slug
      }
      excerpt
      html
    }
  }
`;

const PostTemplate = ({ data }) => {
  const id = data.markdownRemark.id;
  const title = data.markdownRemark.frontmatter.title;
  const name = data.markdownRemark.frontmatter.name;
  const teaser = data.markdownRemark.frontmatter.teaser;
  const date = data.markdownRemark.frontmatter.date;
  const author = data.markdownRemark.frontmatter.author;
  const slug = data.markdownRemark.fields.slug;
  const excerpt = data.markdownRemark.excerpt;

  const disqusConfig = {
    id,
    url: `https://blogs.meterbolic.org/blog/${slug}`,
    title,
  };

  return (
    <Layout canonical={slug}>
      <div className="row">
        <Helmet>
          <title>{title} | Meterbolic Blogs</title>
          <meta name="description" content={DESCRIPTION} />

          <meta name="twitter:card" content="summary"></meta>
          <meta name="twitter:site" content="@meterbolic"></meta>
          <meta name="twitter:image" content={`${BLOG_URL}${teaser}`} />

          <meta property="og:image" content={`${BLOG_URL}${teaser}`} />
          <meta property="og:description" content={excerpt} />
          <meta property="og:title" content={title} />
          <meta property="og:type" content={"website"} />
          <meta property="og:url" content={BLOG_URL} />
        </Helmet>
        <StyledPostTemplate>
          <div className="container">
            <h1>{title || name}</h1>
          </div>
          {teaser ? (
            <div
              className="teaser"
              style={teaser ? { backgroundImage: `url(/${teaser})` } : null}
            />
          ) : null}
          <div className="container">
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />

            <Share
              title={title || name}
              author={authors[author]}
              url={disqusConfig.url}
            />

            {authors[author] ? (
              <Author {...authors[author]} date={date} />
            ) : null}

            <Link className="back" to="/">
              Go Back &nbsp;&rarr;
            </Link>

            <div style={{ margin: "2rem 0" }}>
              <CommentCount config={disqusConfig} placeholder={"..."} />
              <Disqus config={disqusConfig} />
            </div>
          </div>
        </StyledPostTemplate>
      </div>
    </Layout>
  );
};

export default PostTemplate;
